.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.fixed-nav {
  position: fixed;
}

.notify-item {
  position:relative;
  padding-top:15px;
  display:inline-block;
}
.notify-badge{
  position: absolute;
  right:-10px;
  top:5px;
  background:#ffbf00;
  text-align: center;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  color:#333333;
  
  padding: 0px 5px 0px 5px;
  font-size:16px;
}

.notify-badge{
  position: absolute;
  right:-10px;
  top:5px;
  background:#ffbf00;
  text-align: center;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  color:#333333;
  
  padding: 0px 5px 0px 5px;
  font-size:16px;
}

.process__upload-btn {
  display: block;
  margin: 2.5rem auto;
  text-align: center;
}

.line-room-icon {
  display: block;
  border-radius: 10%;
  margin: 0 auto;
  width: 200px;
  height: 200px;
}



.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
   border: 1px solid #000000;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}